import { __awaiter } from "tslib";
import { useAdsCore } from '@slickdeals/ads-core';
import { initEventTracker, } from '@slickdeals/web-instrumentation';
export const initPage = () => __awaiter(void 0, void 0, void 0, function* () {
    useAdsCore().initAdStack();
    let whitelistedEvents = {
        event_names: [],
        event_types: [],
    };
    try {
        const response = yield fetch('/web-api/feature-flag/?key=web-impression-instrumentation-events');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        whitelistedEvents = yield response.json();
    }
    catch (error) {
        console.error('Error fetching feature flag', error);
    }
    initEventTracker({
        client: {
            dataLayer: window.dataLayer,
            url: window.location.href,
            device: {
                userAgent: window.navigator.userAgent,
            },
            whitelistedEvents: {
                names: whitelistedEvents.event_names || [],
                types: whitelistedEvents.event_types || [],
            },
        },
    });
});
